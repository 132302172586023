import React, { useEffect, useState } from "react"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"
import ContactForm from "../components/contact-form.component"

import "./lessons.styles.scss"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

const LessonsPage = ({ location }) => {
  const [packageDetails, setPackageDetails] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "package-details",
      })
      .then(response => {
        setPackageDetails(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout pathname={location.pathname}>
      <Seo
        title="Flute Lessons in Pittsburgh or Remote"
        description="Students at the Parasky Flute Studios are immersed in a comprehensive flute community! In addition to weekly flute lessons, students have access to community workshops, ensembles and more."
      />
      <section className="hero hero--lessons is-medium is-dark">
        <div className="container">
          <div className="hero-body is-flex-direction-column is-justify-content-center has-text-shadow">
            <h1 className="title is-size-2 is-size-1-desktop is-uppercase has-text-centered">
              Flute Lessons
            </h1>
          </div>
        </div>
      </section>
      <section className="lessons__intro">
        <div className="container">
          <div className="columns">
            <div className="column is-half">
              <div className="intro__col1 content is-size-6">
                <div className="intro__accent">
                  <h2>Community</h2>
                  <p>
                    Students at the Parasky Flute Studios are immersed in a
                    comprehensive flute community! In addition to weekly flute
                    lessons, students have access to:
                  </p>
                </div>
                <div className="intro__more">
                  <ul>
                    <li>The Studio Flute Orchestra</li>
                    <li>The Honors Flute Choir</li>
                    <li>Small Flute Ensembles</li>
                    <li>Multiple Annual Recitals</li>
                    <li>Winter Flute Workshop</li>
                    <li>Summer Flute Workshop</li>
                    <li>
                      Competition & Performance opportunities at the local,
                      regional, national & international levels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="intro__col2 content is-size-6">
                <div className="intro__accent">
                  <h2>Teaching Philosophy</h2>
                  <p>
                    Teaching requires a deep understanding of how to connect
                    with a student on a professional, emotional, and
                    inspirational level. I use this concept as a constant
                    reminder to define my role as an educator in the flute
                    studio. Learning is highly individualized for each member of
                    a private studio and my goal is to help a student become
                    their own teacher. I achieve this through demanding yet
                    accessible standards that allow for a flexible approach to
                    accommodate each student as an individual.
                  </p>
                </div>
                <div className="intro__more">
                  <p>
                    As a teacher, I use these guiding principles to create
                    lessons that produce results:
                  </p>
                  <ul>
                    <li>Create a life-long drive for musical growth</li>
                    <li>
                      Bring about an understanding of how to learn effectively
                    </li>
                    <li>
                      Introduce guidelines for empowerment and self-motivation
                    </li>
                    <li>
                      Understand the importance of camaraderie and collaboration
                      at the professional level
                    </li>
                    <li>Establish a correlation with passion and music</li>
                    <li>Generate the desire to share through performing</li>
                    <li>
                      Develop the importance of music via community outreach
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="columns mt-6">
            <div className="column is-full has-text-centered py-4">
              <p>
                <a
                  href="#rates"
                  className="button is-primary arrow-right-to-down"
                >
                  Let's get started <FaArrowRight className="ml-4" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="rates"
        className="lessons__rates has-background-grey-lighter"
      >
        <div className="container container--text">
          <div className="content is-size-6 pb-6 is-flex is-flex-direction-column">
            <h2 className="is-size-2 py-4 has-text-centered is-align-self-center">
              Lesson Rates
            </h2>
            <p>
              New students are welcome to begin lessons at any point during the
              year with Mr. Parasky, Miss VanTine (Assistant Teacher) or Miss
              Heron (Intern Teacher). The introductory Jump Start Package of six
              lessons is the best way for us to meet one another to get started
              on YOUR musical journey. Scholarship is available if financial
              assistance is needed.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="columns is-centered is-flex-wrap-wrap">
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <div className="card lesson is-block is-75-percent-mobile-xl">
                <header className="card-header lesson-header">
                  <h3>Parasky</h3>
                  <h4>30-Minutes</h4>
                  <span className="lesson-price">
                    <span className="lesson-price-amount">
                      <span className="lesson-price-currency has-text-grey-darker">
                        $
                      </span>
                      38
                    </span>
                    /lesson
                  </span>
                </header>
                <div className="card-content lesson-content is-size-6 is-size-5-widescreen">
                  <div className="has-text-centered">
                    Jumpstart Package
                    <span className="lesson-price">
                      <span className="lesson-price-amount">
                        <span className="lesson-price-currency has-text-grey-darker">
                          $
                        </span>
                        228
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <div className="card lesson is-block is-75-percent-mobile-xl">
                <header className="card-header lesson-header">
                  <h3>Parasky</h3>
                  <h4>60-Minutes</h4>
                  <span className="lesson-price">
                    <span className="lesson-price-amount">
                      <span className="lesson-price-currency has-text-grey-darker">
                        $
                      </span>
                      75
                    </span>
                    /lesson
                  </span>
                </header>
                <div className="card-content lesson-content is-size-6 is-size-5-widescreen">
                  <div className="has-text-centered">
                    Jumpstart Package
                    <span className="lesson-price">
                      <span className="lesson-price-amount">
                        <span className="lesson-price-currency has-text-grey-darker">
                          $
                        </span>
                        450
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <div className="card lesson is-block is-75-percent-mobile-xl">
                <header className="card-header lesson-header">
                  <h3>VanTine</h3>
                  <h4>30-Minutes</h4>
                  <span className="lesson-price">
                    <span className="lesson-price-amount">
                      <span className="lesson-price-currency has-text-grey-darker">
                        $
                      </span>
                      28
                    </span>
                    /lesson
                  </span>
                </header>
                <div className="card-content lesson-content is-size-6 is-size-5-widescreen">
                  <div className="has-text-centered">
                    Jumpstart Package
                    <span className="lesson-price">
                      <span className="lesson-price-amount">
                        <span className="lesson-price-currency has-text-grey-darker">
                          $
                        </span>
                        168
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <div className="card lesson is-block is-75-percent-mobile-xl">
                <header className="card-header lesson-header">
                  <h3>Heron</h3>
                  <h4>30-Minutes</h4>
                  <span className="lesson-price">
                    <span className="lesson-price-amount">
                      <span className="lesson-price-currency has-text-grey-darker">
                        $
                      </span>
                      23
                    </span>
                    /lesson
                  </span>
                </header>
                <div className="card-content lesson-content is-size-6 is-size-5-widescreen">
                  <div className="has-text-centered">
                    Jumpstart Package
                    <span className="lesson-price">
                      <span className="lesson-price-amount">
                        <span className="lesson-price-currency has-text-grey-darker">
                          $
                        </span>
                        138
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="is-flex is-flex-direction-column is-justify-content-center is-align-content-center gap-4">
            <a
              disabled={packageDetails ? false : true}
              href={
                packageDetails
                  ? packageDetails.fields.file.fields.file.url
                  : "#"
              }
              className="button is-text is-align-self-center"
              target="_blank"
              rel="noreferrer"
            >
              JumpStart Package Details
            </a>
            <a
              href="#contact"
              className="button has-background-primary-light arrow-right-to-down px-6 is-align-self-center"
            >
              Book Now <FaArrowRight className="ml-4" />
            </a>
          </div>
        </div>
      </section>
      <section id="contact" className="lessons__contact py-6">
        <div className="container">
          <div className="content is-flex is-flex-direction-column">
            <h3 className="has-border-bottom-info-1 is-align-self-center">
              Contact me to book a lesson
            </h3>
          </div>
          <ContactForm />
        </div>
      </section>
    </Layout>
  )
}

export default LessonsPage
